
import { defineComponent, onMounted, ref, watch, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import { translate, statusColor } from "@/core/services/HelpersFun";
// import BotDetails from "@/views/pages/Admin/bots/component/BotDetails.vue";
import ChangePackageTime from "@/views/pages/Admin/bots/component/ChangePackageTime.vue";
// import DetailsModal from "@/views/pages/Admin/bots/component/detailsModal.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import JwtService from "@/core/services/JwtService";
// import proxyModal from "@/views/pages/Admin/bots/component/changeProxyModal.vue";

import Platform from "@/components/Platform.vue";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { storeToRefs } from "pinia";
import { Servers } from "@/store/pinia/Servers";

export default defineComponent({
  name: "kt-transaction-management-table",
  components: {
    // BotDetails,
    // DetailsModal,
    UserAvatar,
    ChangePackageTime,
    Platform,
    // proxyModal,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    // const proxymodalOpened = ref(0);
    const loading = ref(true);
    const openDialog = ref(false);
    const list = ref();
    // const details_dialog = ref(false);
    const selected_bot = ref();
    const selected_server = ref();
    const selectedBot = ref();
    const selectedBotId = ref();
    const page = ref(1);
    const filter = ref({
      q: "",
      status: '',
      buy_with_discount: null,
      free_bots: null,
      without_total_discount: null,
      only_total_discount: null,
      only_bought_bots: null,
      only_online: null,
      only_admins_bots: null,
      without_admins_bots: null,
      page: page.value,
      with_user: true,
      user_id: "",
      with_platforms: true,
    });
    const changeServerValue = ref({});
    const resetFilter = () => {
      filter.value.status = '';
      filter.value.buy_with_discount = null;
      filter.value.free_bots = null;
      filter.value.without_total_discount = null;
      filter.value.only_total_discount = null;
      filter.value.only_bought_bots = null;
      filter.value.only_online = null;
      filter.value.only_admins_bots = null;
      filter.value.without_admins_bots = null;
      filter.value.page = 1;
      getItems();
    };

    const getItems = () => {
      loading.value = true;
      ApiService.query("admin/bot", { params: filter.value })
        .then(({ data }) => {
          loading.value = false;
          list.value = data.data.bots.data;
          list.value.map((item, index) => {
            changeServerValue.value[index] = item.server;
          });
          pagination.value = data.data.bots.pagination;
        })
        .catch(() => {
          loading.value = false;
        });
    };

    // const serverHolder = Servers();
    // const { serverList } = storeToRefs(serverHolder);
    // const { fetchServersList, reboot, changeBotServer } = serverHolder;

    const pagination = ref();

    const handleCurrentChange = (val: number) => {
      filter.value.page = val;
      getItems();
    };

    const deleteBot = (id) => {
      ApiService.post(`admin/bot/${id}/delete`, {})
        .then(() => {
          list.value = list.value.filter((item) => item.bot_id !== id);
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data);
        });
    };

    const powerOff = (id) => {
      ApiService.post(`admin/bot/${id}/power-off`, {})
        .then(() => {
          list.value = list.value.filter((item) => item.bot_id !== id);
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data);
        });
    };
    const permission = ref();
    const userType = ref();

    const excelLink = computed(() => {
      let params = { ...filter.value };
      var queryString = Object.keys(filter.value)
        .map((key) => {
          return (
            encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
          );
        })
        .join("&");

      return (
        process.env.VUE_APP_BACK_URL +
        `bots/download?api_token=${JwtService.getToken()}&${queryString}`
      );
    });

    onMounted(() => {
      setTimeout(() => {
        let per: any = localStorage.getItem("permissions");
        permission.value = JSON.parse(per);
        let user: any = localStorage.getItem("user_info");
        userType.value = JSON.parse(user).type;
      }, 3000);

      getItems();
      // fetchServersList();
      setCurrentPageBreadcrumbs("مدیریت اشتراک ها", []);
    });

    const searchTerm = ref("");
    const searchResults = ref();
    let timer = Number as any;
    //
    // watch(searchTerm, async (newSearchTerm) => {
    //   // Perform a search with the new search term
    //   const results = await performSearch(newSearchTerm);
    //   searchResults.value = results;
    // });

    watch(searchTerm, async (newSearchTerm) => {
      // Clear the previous timer
      clearTimeout(timer);

      // Set a new timer to delay the search request by 2 seconds
      timer = setTimeout(async () => {
        // Perform a search with the new search term
        const results = await performSearch(newSearchTerm);
        searchResults.value = results;
      }, 2000);
    });

    const users = ref();
    const loadingSearchUser = ref(false);
    async function performSearch(searchTerm) {
      loadingSearchUser.value = true;
      const response = await ApiService.query("admin/user", {
        params: { q: searchTerm },
      });

      const results = await response;
      if (results && results.data) {
        loadingSearchUser.value = false;
        users.value = results.data.data.users.data;
      }
    }

    // const handleChangeBotServer = (event, bot_id, platform) => {
    //   changeBotServer(bot_id, platform, event.target.value);
    // };

    return {
      loading,
      list,
      // details_dialog,
      selected_bot,
      selected_server,
      pagination,
      handleCurrentChange,
      page,
      getItems,
      translate,
      statusColor,
      filter,
      resetFilter,
      selectedBot,
      selectedBotId,
      // serverList,
      changeServerValue,
      // changeBotServer,
      // handleChangeBotServer,
      openDialog,
      deleteBot,
      powerOff,
      // proxymodalOpened,
      searchTerm,
      searchResults,
      performSearch,
      users,
      loadingSearchUser,
      // reboot,
      permission,
      userType,
      excelLink,
    };
  },
});
