
import { defineComponent, watch } from "vue";
import { ref } from "vue";
import DatePicker from "vue3-persian-datetime-picker";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import store from "@/store";
import { getLocale } from "@/core/services/HelpersFun";
export default defineComponent({
  name: "change-time-expire",
  components: {
    DatePicker,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    openDialog: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const submitButton = ref<HTMLButtonElement | null>(null);

    const dialogFormVisible = ref(props.openDialog);

    const dataHolder = ref({
      expiry_date:
        props.bot && props.bot?.package
          ? props.bot?.package?.expired_date?.gregorian
          : new Date().toISOString().split("T")[0],
      remove_sending_limit: false,
    });

    watch(props, () => {
      dialogFormVisible.value = true;
    });

    const submit = () => {
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      ApiService.setHeader();
      ApiService.post(
        `admin/bot/${props.bot.bot_id}/change-expiry-time`,
        dataHolder.value
      )
        .then(() => {
          dialogFormVisible.value = false;
        })
        .catch((e) => {
          store.commit(Mutations.SET_ERROR, e.response.data);
        })
        .finally(() => {
          if (submitButton.value) {
            //Deactivate indicator
            submitButton.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
              submitButton.value!.disabled = false;
          }
        });
    };

    return {
      submit,
      dialogFormVisible,
      dataHolder,
      translate,
      getLocale,
    };
  },
});
