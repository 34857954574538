import { defineStore } from "pinia";
import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { ElNotification } from "element-plus/es";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export const Servers = defineStore("allServers", () => {
  const store = useStore();

  // state
  const serverList = ref();
  const loadingServers = ref(false);
  const tryCounter = ref(0);
  const page = ref(1);
  const status = ref(); // enable | disable
  const paginationServers = ref();

  const fetchServersList = async () => {
    loadingServers.value = true;

    ApiService.query("admin/server", {
      params: {
        page: page.value,
        status: status.value,
      },
    })
      .then(({ data }) => {
        serverList.value = data.data.servers.data;
        paginationServers.value = data.data.servers.pagination;
        tryCounter.value = 0;
        
      })
      .catch(({ response }) => {
        // if (tryCounter.value < 3) {
        //   tryCounter.value++;
        //   fetchServersList();
        // }
        if (response && response.data) {
          store.commit(Mutations.SET_ERROR, response.data);
        }
      })
      .finally(() => {
        loadingServers.value = false;
      });
  };

  const reboot = (id) => {
    ApiService.post(`admin/server/${id}/reboot`, [])
      .then(() => {
        fetchServersList();
      })
      .catch(() => {
        ElNotification({
          title: "خطا",
          message: "خطا در حذف سرور!",
          type: "error",
        });
      });
  };

  const changeBotServer = (id, plat, server_id) => {
    ApiService.setHeader();

    Swal.fire({
      title: "آیا مطمئنید می‌خواهید سرور این اشتراک را تغییر دهید؟",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "بله",
      denyButtonText: "خیر",
    }).then((result) => {
      if (result.isConfirmed) {
        ApiService.post(`/admin/bot/${id}/change-server`, {
          server_id: server_id,
          platform: plat,
        })
          .then(() => {
            Swal.fire({
              text: "تغییر سرور انجام شد",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "بسیار خب",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          })
          .catch(({ response }) => {
            if (response && response.data)
              store.commit(Mutations.SET_ERROR, response.data);
          });
      }
    });
  };

  return {
    reboot,
    fetchServersList,
    changeBotServer,

    serverList,
    loadingServers,
    page,
    paginationServers,
  };
});
